<template>
  <DialogVentana :dialog.sync="dialogInput" :loading.sync="loading" :width="500">
        <v-card style="box-shadow: none;">
      <v-card-text>
        <p class="display-1 primary--text font-weight-regular mt-2"> {{modoInput}} {{titulo}}</p>
        <v-form ref="form" v-model="valid">
          <v-text-field
            label="Nombre"
            v-model="nombre"
            style="margin-bottom: -5px;"
            :rules="[requerido]"
            counter
            maxlength="45"
            @change="existeMetodo"
            @keydown="checkKeyDownAlphaNumeric($event)"
          ></v-text-field>
          <v-textarea
            v-model="notas"
            auto-grow
            filled
            label="Notas"
            rows="2"
            :rules="[requerido]"
            counter
            maxlength="100"
            class="mt-6"
          ></v-textarea>
          <v-row justify="center">
            <v-btn
              color="primary"
              @click="guardar"
              :disabled="!valid"
            >
            GUARDAR
            </v-btn>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </DialogVentana>
</template>

<script>
import ApiCon from '@/logic/apicon.js'
import Globales from '@/mixins/globales.vue'
import DialogVentana from '@/components/dialogventana.vue'

export default {
  data: () => ({
    titulo: 'Método',
    nombre: '',
    notas: '',
    loading: false,
    valid: false,
    requerido: v => !!v || 'Este campo es obligatorio',
    idmetodo: 0
  }),
  mixins: [
    Globales
  ],
  components: {
    DialogVentana
  },
  props: ['modo', 'dialogMET', 'nmetodo', 'origen'],
  methods: {
    reset () {
      this.nombre = ''
      this.notas = ''
      this.idmetodo = 0
    },
    async existeMetodo () {
      if (this.nombre !== '') {
        const response = await ApiCon.get2('metodos/existe', this.nombre, this.$session.get('usuario'))
        if (response.status === 200) {
          if (response.data === true) {
            this.nombre = ''
            alert('El método ya se encuentra registrado.')
          }
        } else { alert('error existeMetodo') }
      }
    },
    async cargarMetodo () {
      this.loading = true
      const response = await ApiCon.get2('metodos/unonombre', this.nmetodo, this.$session.get('usuario'))
      if (response.status === 200) {
        this.nombre = response.data.nombre
        this.notas = response.data.notas
        this.idmetodo = response.data.idmetodo
      } else { alert('Error en cargarMetodo...') }
      this.loading = false
    },
    postMod () {
      if (this.origen === 'select') {
        this.cargarTabla('metodos/listado', 'cambiarMetodos', 'cargarListaMetodos')
        this.nmetodoInput = this.nombre
      } else if (this.origen === 'tabla') {
        this.cargarTabla('metodos/todo', 'cambiarTabla', 'cargarTablaMetodos')
      }
      this.$refs.form.reset()
      this.reset()
      this.dialogInput = false
    },
    async guardar () {
      if (this.valid === true) {
        this.loading = true
        var ins = {}
        if (this.modo === 'NUEVO') {
          ins = {
            nombre: this.nombre,
            notas: this.notas,
            usuario: this.$session.get('usuario')
          }
          const response = await ApiCon.post('metodos/insert', ins)
          if (response.status === 200) {
            alert('El método ha sido registrado.')
            this.postMod()
          } else { alert('Ha ocurrido un error con el guardado de datos') }
        } else if (this.modo === 'EDITAR') {
          ins = {
            id: this.idmetodo,
            nombre: this.nombre,
            notas: this.notas
          }
          const response = await ApiCon.post('metodos/update', ins)
          if (response.status === 200) {
            alert('El método ha sido actualizado.')
            this.postMod()
          } else { alert('Ha ocurrido un error con la edición de datos') }
        }
        this.loading = false
      } else {
        alert('Corrobore si todos lo datos están ingresados correctamente')
      }
    }
  },
  computed: {
    dialogInput: {
      get: function () {
        return this.dialogMET
      },
      set: function (newValue) {
        this.$emit('update:dialogMET', newValue)
      }
    },
    modoInput: {
      get: function () {
        return this.modo
      },
      set: function (newValue) {
        this.$emit('update:modo', newValue)
      }
    },
    nmetodoInput: {
      get: function () {
        return this.nmetodo
      },
      set: function (newValue) {
        this.$emit('update:nmetodo', newValue)
      }
    },
    origenInput: {
      get: function () {
        return this.origen
      },
      set: function (newValue) {
        this.$emit('update:origen', newValue)
      }
    }
  },
  watch: {
    dialogMET () {
      if (this.dialogMET === false) {
        this.$refs.form.reset()
        this.reset()
      } else {
        if (this.modo === 'EDITAR') {
          this.cargarMetodo()
        }
      }
    }
  }
}
</script>
