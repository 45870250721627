<template>
  <div id="metodos">
    <MenuBase
      :tipo="'metodos'"
      :headers="headers"
      :items="$store.state.tabla"
      :botones="botones"
      :opciones="opciones"
      :alineaHeaders='alineaHeaders'
      :titulo="titulo"
      :tituloOpciones="tituloOpciones"
      :campoOpcion="campoOpcion"
      @clic-boton="clicBoton"
      @clic-fila="clicFila"
      :icono="'mdi-check'"
      :verTabla="true"
      :loading="!$store.state.tabla.length"
    />
    <Metodo :modo="modoMetodo" :dialogMET.sync="dialogMetodo" :nmetodo.sync="this.campoOpcion" :origen.sync="origen"/>
  </div>
</template>

<script>
import Globales from '@/mixins/globales.vue'
import MenuBase from '@/components/menubase.vue'
import Metodo from '@/views/metodo.vue'

export default {
  mixins: [
    Globales
  ],
  components: {
    MenuBase,
    Metodo
  },
  data: () => ({
    headers: [],
    items: [],
    botones: [],
    opciones: [],
    titulo: 'Métodos',
    dialog: false,
    tituloOpciones: 'Opciones método: ',
    campoOpcion: '',
    alineaHeaders: 'start',
    modoMetodo: 'NUEVO',
    dialogMetodo: false,
    origen: 'tabla'
  }),
  methods: {
    interfaz () {
      this.headers = [
        { text: 'NOMBRE', align: 'start', value: 'nombre' },
        { text: 'NOTAS', align: 'start', value: 'notas' }
      ]
      this.botones = [
        { id: 1, nombre: 'NUEVO MÉTODO', color: 'primary', accion: 'nuevoMetodo' }
      ]
      this.opciones = [
        { id: 1, nombre: 'EDITAR MÉTODO', color: 'primary', accion: 'editarMetodo' }
        // { id: 2, nombre: 'QUITAR MÉTODO', color: 'error', accion: 'quitarMetodo' }
      ]
    },
    clicFila (fila) {
      this.campoOpcion = fila.nombre
      this.dialog = true
    },
    clicBoton (accion) {
      if (accion === 'nuevoMetodo') {
        this.modoMetodo = 'NUEVO'
        this.dialogMetodo = true
      } else if (accion === 'editarMetodo') {
        this.modoMetodo = 'EDITAR'
        this.dialogMetodo = true
      } else if (accion === 'quitarMetodo') {
        alert('Se quitará éste método como parámetro seleccionable')
      }
    },
    cargarItems () {
      this.cargarTabla('metodos/todo', 'cambiarTabla', 'cargarTablaMedidas')
    },
    cargarDatos () {
      if (this.compruebaLogin() === true) {
        if (this.returnPrilegio('metodos') === true) {
          this.interfaz()
          this.cargarItems()
        } else { this.$router.push('/menu') }
      }
    }
  },
  created () {
    this.haySesion()
    this.cargarDatos()
  }
}
</script>

<style scoped>
  .v-data-table.v-data-table.v-data-table >>> td  {
    font-size: 1.0rem !important;
    font-weight:500;
  }
  .v-data-table.v-data-table.v-data-table >>> th  {
    font-size: 1.0rem !important;
    font-weight:500;
    color: cornflowerblue;
  }
</style>
